/* AddTopicModal.css */

/* 공통 스타일 */
.add-topic-btn-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 20px;
    width: 100%;
}

.add-topic-btn {
    background-color: #4a90e2;
    color: white;
    border: none;
    padding: 12px 24px;
    border-radius: 12px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 600;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.add-topic-btn:hover {
    background-color: #357abd;
    transform: translateY(-3px); /* 떠오르는 효과 */
}

.topic-input {
    width: 20%;
    max-width: 500px;
    padding: 10px 15px;
    margin: 5px;
    font-size: 16px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    outline: none;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.topic-input:focus {
    border-color: #007bff; /* 포커스 시 테두리 색상 */
    box-shadow: 0px 4px 12px rgba(0, 123, 255, 0.3); /* 포커스 시 그림자 강조 */
}

/* 다크 모드 검색창 스타일 */
[data-theme="dark"] .topic-input {
    background-color: #333; /* 다크 모드 배경색 */
    color: #e0e0e0; /* 다크 모드 텍스트 색상 */
    border: 1px solid #555; /* 다크 모드 테두리 */
}

[data-theme="dark"] .topic-input:focus {
    border-color: #66aaff; /* 다크 모드 포커스 색상 */
    box-shadow: 0px 4px 12px rgba(102, 170, 255, 0.3); /* 다크 모드 포커스 그림자 */
}
/* AddTopicModal.css */

/* 모달 배경 */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  /* 모달 본문 */
  .modal {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    max-width: 90%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
  }

  [data-theme="dark"] .modal {
    background-color: #333; /* 다크 모드 배경색 */
    color: #e0e0e0; /* 다크 모드 텍스트 색상 */
    border: 1px solid #555; /* 다크 모드 테두리 */
}
  
  /* 버튼 스타일 */
  .modal-actions {
    display: flex;
    justify-content: space-between;
  }
  
  .edit-button {
    padding: 10px 15px;
    border: none;
    background-color: #007bff;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .edit-button:hover {
    background-color: #0056b3;
  }
  
  .new-topic-input {
    width: 89%;
    max-width: 500px;
    padding: 10px 15px;
    margin: 5px;
    margin-bottom: 15px;
    font-size: 16px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    outline: none;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
  }