.memo-input-container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%; /* 부모 요소에 맞게 조정 */
    max-width: 500px; /* 최대 너비 제한 */
    height: 38%;
    box-sizing: border-box;
}

.memo-input-form {
    display: flex;
    flex-direction: column;
    width: 100%; /* 부모 요소에 맞게 조정 */
    max-width: 100%; /* 부모 요소를 넘지 않도록 설정 */
    height: auto;
    margin: 15px auto;
    margin-top: 0;
    padding: 22px;
    border: 1px solid #ced4da;
    border-radius: 8px;
    background-color: rgb(250, 250, 250);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    box-sizing: border-box;
    flex-grow: 1; 
}

/* 입력창과 텍스트 영역이 부모 크기에 맞도록 조정 */
.memo-input-form input,
.memo-input-form textarea {
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
}

/* 반응형: 작은 화면에서 폼 크기 조정 */
@media screen and (max-width: 600px) {
    .memo-input-container {
        max-width: 90%; /* 모바일 환경에서 좀 더 유동적으로 조정 */
    }

    .memo-input-form {
        padding: 15px; /* 작은 화면에서는 패딩 조정 */
    }
}


/* 다크 모드 */
[data-theme="dark"] .memo-input-form {
    background-color: #333; /* 어두운 모드 배경색 */
    border: 1px solid #444; /* 어두운 모드 테두리 색 */
    border-radius: 5px; /* 테두리 모서리 둥글게 */
}

.memo-input-form input {
    margin: 5px 0;
    padding: 10px;
    border: 1px solid #ccc; /* 기본 테두리 색 */
    border-radius: 5px;
    width: 100%; /* 입력창 가로를 form에 맞추기 */
    box-sizing: border-box; /* padding 포함하여 width 계산 */
    background-color: rgb(250, 250, 250); 
}

/* 다크 모드 입력창 */
[data-theme="dark"] .memo-input-form input {
    border: 1px solid #666; /* 어두운 모드에서 입력창 테두리 색 */
    background-color: #555; /* 어두운 모드 입력창 배경색 */
    color: #eee; /* 어두운 모드 텍스트 색 */
}

/* 텍스트 영역 스타일 */
.memo-input-form textarea {
    margin: 5px 0;
    padding: 10px;
    border: 1px solid #ccc; /* 기본 테두리 색 */
    border-radius: 5px;
    height: 70%;
    resize: vertical; /* Allow only vertical resizing */
    width: 100%; /* Set a fixed width */
    max-height: calc(100vh - 200px); /* Limit height based on viewport size */
    box-sizing: border-box; /* Ensure padding is included in the width */
    background-color: rgb(250, 250, 250); 
}

/* 다크 모드 텍스트 영역 */
[data-theme="dark"] .memo-input-form textarea {
    border: 1px solid #666; /* 어두운 모드에서 텍스트 영역 테두리 색 */
    background-color: #555; /* 어두운 모드 텍스트 영역 배경색 */
    color: #eee; /* 어두운 모드 텍스트 색 */
}

/* 동기화 버튼 */
.sync-control {
    position: absolute;
    top: 4px;
    right: 3px;
    display: flex;
    align-items: center;
}

.loading-icon {
    font-size: 14px;
    color: #666;
}

.sync-button {
    padding: 8px 12px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.sync-button:disabled {
    background-color: #999;
    cursor: not-allowed;
}

.sync-button:hover:not(:disabled) {
    background-color: #0056b3;
}

/* MemoInput.css */
.sync-icon {
    width: 24px; /* 적절한 크기로 조정 */
    height: 24px;
    cursor: pointer;
    transition: transform 0.2s;
}

.sync-icon:hover {
    transform: scale(1.1); /* 확대 효과 */
}

.sync-icon.loading {
    animation: rotate 1s linear infinite;
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
