.topic-content-name {
    font-size: 1.75rem;
    color: #2a2a2a;
    margin: 10px 10px;
}

.topic-content-container {
    position: relative;
    padding: 20px;
    width: 80vh;
    max-width: 800px;
    margin: 80px auto;
    background-color: #f7f7f7;
    border-radius: 16px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.topic-title-search-input {
    display: block; /* input 요소가 블록처럼 동작하도록 */
    width: 90%;
    max-width: 500px; /* 최대 너비 */
    padding: 10px 15px;
    margin: 10px auto; /* 수평 중앙 정렬 */
    font-size: 16px;
    border: 1px solid #e0e0e0; /* 기본 테두리 */
    border-radius: 8px;
    outline: none;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1); /* 입력창 그림자 */
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.topic-title-search-input:focus {
    border-color: #007bff; /* 포커스 시 테두리 색상 */
    box-shadow: 0px 4px 12px rgba(0, 123, 255, 0.3); /* 포커스 시 그림자 강조 */
}

[data-theme="dark"] .topic-title-search-input {
    display: block;
    background-color: #333; /* 다크 모드 배경색 */
    color: #e0e0e0; /* 다크 모드 텍스트 색상 */
    border: 1px solid #555; /* 다크 모드 테두리 */
}


[data-theme="dark"] .topic-title-search-input:focus {
    border-color: #66aaff; /* 다크 모드 포커스 색상 */
    box-shadow: 0px 4px 12px rgba(102, 170, 255, 0.3); /* 다크 모드 포커스 그림자 */
}

[data-theme="dark"] .topic-content-container {
    background-color: #444;
    color: #e0e0e0;
    border: 1px solid #555;
}

[data-theme="dark"] .topic-content-name {
    color: #e0e0e0;
}