.calendar {
    width: 100%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    background-color: rgb(250, 250, 250);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    height: 400px; /* 달력의 고정된 높이 설정 */
}

/* 다크 모드 */
[data-theme="dark"] .calendar {
    border: 1px solid #333; /* 어두운 모드 테두리 색 */
    padding: 20px;
    background-color: #333;
    border-radius: 5px;
}

.calendar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2%;
    background-color: #f7f7f7;
    color: #333;
    padding-bottom: 0px;
    border-bottom: 1px solid #ccc; /* 실선 추가 */
    margin-bottom: 15px;
}

/* 다크 모드 */
[data-theme="dark"] .calendar-header {
    background-color: #555; /* 어두운 모드 배경색 */
    color: #eee; /* 다크 모드 텍스트 색 */
}

.calendar-title {
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
}

/* 다크 모드에서 제목 색상 */
[data-theme="dark"] .calendar-title {
    color: #eee; /* 어두운 모드에서 텍스트 색상 */
}

.day-names {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    text-align: center;
    background-color: #f0f0f0;
    font-weight: bold;
    padding: 5px 0;
}

/* 다크 모드 */
[data-theme="dark"] .day-names {
    background-color: #444;
    color: #eee;
}


.calendar-days {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-auto-rows: 1fr; /* 각 날짜의 행 높이를 균등하게 분배 */
    gap: 0;
    overflow: hidden; /* 넘치지 않도록 설정 */
    height: calc(100% - 120px); /* 헤더와 요일 부분을 제외한 나머지 공간을 차지 */
}

/* 다크 모드 */
[data-theme="dark"] .calendar-days {
    border-top: 1px solid #666; /* 어두운 모드 테두리 색 */
}

.calendar-day {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
    padding: 10px;
    border: 1px solid #ddd;
    transition: background-color 0.3s;
    box-sizing: border-box;
    height: 100%; /* 날짜 상자의 높이를 자동으로 비례적으로 설정 */
    overflow: hidden; /* 날짜 텍스트가 넘치지 않도록 */
}

/* 다크 모드 */
[data-theme="dark"] .calendar-day {
    color: #eee; /* 다크 모드 텍스트 색 */
    border: 1px solid #666; /* 어두운 모드에서 테두리 색 */
    background-color: #444;
}

.calendar-day:hover {
    background-color: #e0e0e0; /* 기본 호버 색상 */
}

/* 다크 모드 호버 상태 */
[data-theme="dark"] .calendar-day:hover {
    background-color: #666; /* 어두운 모드 호버 색상 */
}

.has-memo {
    background-color: #ccffcc; /* 기본 메모 있음 상태 */
}

/* 다크 모드 */
[data-theme="dark"] .has-memo {
    background-color: #3c763d; /* 어두운 모드에서 메모 있음 상태 */
}


/* 오늘 날짜 표시 - 원형 유지 (가로 세로 비율 상관없이 유지) */
.calendar-day.today {
    background-color: #339AF0;
    color: white;
    font-weight: bold;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
}

.calendar-day.today:hover {
    background-color: skyblue; /* 기본 오늘 날짜 호버 색상 */
}

/* 메모가 있는 날짜 */
.calendar-day.has-memo {
    background-color: skyblue;
    color: black;
}

.calendar-day.has-memo:hover {
    background-color: rgb(73, 183, 226);
}

/* 빈칸 */
.calendar-day.empty {
    background: none;
    border: none;
}

/* 화살표 버튼 */
.arrow-button {
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    color: #007bff; /* 기본 화살표 버튼 색상 */
}

/* 다크 모드 화살표 버튼 색상 */
[data-theme="dark"] .arrow-button {
    color: #66b3ff; /* 어두운 모드에서 화살표 색상 */
}