.navbar {
  background: linear-gradient(90deg, #4e54c8, #8f94fb); /* 그라데이션 추가 */
  color: #fff;
  padding: 15px 10px; /* 수평 padding 제거 */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  transition: background-color 0.3s ease;
  box-sizing: border-box; 
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 0 15px; 
  width: 100%;
  max-width: 1200px; 
  box-sizing: border-box;
}

.navbar-brand {
  font-size: 1.75rem;
  font-weight: bold;
  color: #fff;
  text-decoration: none;
}

.navbar-menu {
  display: flex;
  gap: 20px;
  flex-wrap: nowrap; 
}

/* 모든 메모 보기 링크 스타일 */
.memo-link {
  color: #ffffff; /* 텍스트 색상 */
  font-size: 1rem;
  padding: 8px 16px; /* 패딩 조정 */
  border-radius: 4px; /* 둥근 모서리 */
  text-decoration: none;
  background: #4e54c8; /* 흰색 배경 */
  transition: background-color 0.3s ease, color 0.3s ease;
  white-space: nowrap; /* 텍스트 줄바꿈 방지 */
  box-shadow: none; /* 그림자 제거 */
}

.memo-link:hover {
  background-color: #cfd1e8; /* 호버 시 배경 색상 변경 */
  color: #262fdf; /* 호버 시 텍스트 색상 변경 */
}

.memo-link:active {
  transform: scale(0.95);
}

/* 로그인/로그아웃 링크 스타일 */
.auth-link {
  color: #fff;
  font-size: 1rem;
  padding: 8px 16px;
  border-radius: 4px;
  text-decoration: none;
  background: rgba(255, 255, 255, 0.1);
  transition: background-color 0.3s ease, color 0.3s ease;
  white-space: nowrap; /* 텍스트 줄바꿈 방지 */
}

.auth-link:hover {
  background-color: rgba(255, 255, 255, 0.3);
  color: #fff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.auth-link:active {
  transform: scale(0.95);
}

/* 모바일 환경에 맞춘 검색창 스타일 */
@media (max-width: 437px) {
  .navbar-brand {
    font-size: 1.3rem;
  }
  .memo-link {
    font-size: 0.7rem;
  }
  .auth-link {
    font-size: 0.7rem;
  }
}

/* 사이드바 스타일 */
.sidebar {
  position: fixed;
  top: 0;
  right: -300px; /* 사이드바를 오른쪽 밖으로 설정 */
  width: 300px; /* 사이드바 너비 */
  height: 100%;
  background-color: #333; /* 사이드바 배경색 */
  transition: right 0.3s ease; /* 부드러운 열기/닫기 효과 */
  box-shadow: -2px 0 10px rgba(0, 0, 0, 0.5); /* 그림자 효과 */
  z-index: 1000; /* 다른 요소 위에 나타나도록 설정 */
}

.sidebar.open {
  right: 0; /* 열릴 때 화면에 보이도록 설정 */
}

/* 사이드바 닫기 버튼 */
.close-sidebar-button {
  background: none;
  border: none;
  color: white; /* 닫기 버튼 색상 */
  font-size: 24px; /* 닫기 버튼 크기 */
  cursor: pointer;
  margin: 10px; /* 마진 추가 */
  float: right; /* 오른쪽 정렬 */
}

/* 사이드바 콘텐츠 스타일 */
.sidebar-content {
  padding: 20px; /* 패딩 추가 */
  padding-top: 40px;
  color: white; /* 텍스트 색상 */
  font-family: Arial, sans-serif; /* 기본 폰트 설정 */
}

.sidebar-divider {
  border: none; /* 기본 경계 제거 */
  border-top: 1px solid #444; /* 상단 경계선 추가 */
  margin: 5px 0; /* 상하 여백 추가 */
}

/* 링크 스타일 */
.sidebar-link {
  display: block; /* 블록 형태로 표시 */
  color: #fff; /* 링크 색상 */
  text-decoration: none; /* 링크 기본 스타일 제거 */
  padding: 10px 15px;
  margin: 15px 0;
  transition: background-color 0.3s ease; /* 배경색 전환 효과 */
  border-radius: 4px; /* 둥근 모서리 */
}

.sidebar-link:hover {
  background-color: #444; /* hover 시 배경색 변경 */
}

/* 서랍 아이콘 버튼 스타일 */
.sidebar-toggle-button {
  background: none;
  border: none;
  color: white; /* 서랍 아이콘 색상 */
  font-size: 24px; /* 서랍 아이콘 크기 */
  cursor: pointer;
}

/* 사이드바 스타일 추가 */
.divider {
  border: none; /* 기본 경계 제거 */
  border-top: 1px solid #444; /* 상단 경계선 추가 */
  margin: 10px 0; /* 상하 여백 추가 */
}

/* 사이드바 노트 스타일 */
.sidebar-note {
  color: #aaa; /* 회색 텍스트 */
  font-size: 14px; /* 폰트 크기 조정 */
  margin: 10px 0; /* 상하 여백 추가 */
}

.theme-button {
  display: block;
  align-content: center;
  color: #fff;
  text-decoration: none;
  padding: 10px 15px;
  transition: background-color 0.3s ease;
  border-radius: 4px;
}

.theme-button:hover {
  background-color: #444; /* hover 시 배경색 변경 */
}

.profile-picture {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid #ccc;
  object-fit: cover;
}

.profile-icon {
  font-size: 32px;
  cursor: pointer;
}
