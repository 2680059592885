/* TrashBin.css */
.trash-bin {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 20px;
    height: 20px;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    opacity: 0.7;
    transition: opacity 0.3s ease-in-out;
  }
  
  .trash-bin.hover {
    opacity: 1; /* 드래그 대상이 오버될 때 불투명도를 1로 설정 */
    background-color: rgba(190, 0, 0, 0.3); /* 배경 색 변경 */
  }
  
  .trash-icon {
    width: 40px; /* 아이콘 크기 */
    height: 40px;
  }
  