.topic-list-container {
    position: relative;
    padding: 20px;
    width: 80vh;
    max-width: 800px;
    margin: 80px auto;
    background-color: #f7f7f7;
    border-radius: 16px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.topic-main-title {
    font-size: 1.75rem;
    color: #2a2a2a;
    margin: 10px 10px;
}

.topic-divider {
    border: none;
    border-top: 1px solid #888;
    margin: 15px 0;
}

/* 토픽 리스트 스타일 */
.topics {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    gap: 20px;
}

/* 주제 카드 스타일 */
.topic-item {
    background-color: #fff;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease, transform 0.3s ease;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 220px; /* 카드 높이 고정 */
}

.topic-item:hover {
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    transform: translateY(-2px); /* Lift effect on hover */
}

.topic-item .topic-title {
    font-size: 1.2rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 15px;
}

.topic-item .topic-description {
    font-size: 1rem;
    color: #777;
    flex-grow: 1;
}

.topic-item .view-topic-btn {
    background-color: #4a90e2;
    color: white;
    border: none;
    padding: 10px 18px;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 600;
    margin-top: 15px;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.topic-item .view-topic-btn:hover {
    background-color: #357abd;
    transform: translateY(-2px); /* 버튼 효과 */
}

.edit-button {
    min-width:55px;
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    margin: 0 5px;
}

/* 다크 모드 */
[data-theme="dark"] .topic-list-container {
    background-color: #444;
    color: #eee;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

[data-theme="dark"] .topic-main-title {
    color: #fff;
}

[data-theme="dark"] .topic-item {
    background-color: #555;
    color: #eee;
}

[data-theme="dark"] .topic-item:hover {
    box-shadow: 0 4px 20px rgba(255, 255, 255, 0.1);
}

[data-theme="dark"] .view-topic-btn {
    background-color: #5a9bd8;
}

[data-theme="dark"] .view-topic-btn:hover {
    background-color: #4179b1;
}

/* 모바일 환경에 맞춘 검색창 스타일 */
@media (max-width: 437px) {
  .topic-list-container {
    width: 80%;
  }
}

@media (max-width: 875px) {
  .topic-list-container {
    width: 90%;
  }
}
