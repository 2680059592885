body {
    width: 100vw;
    font-family: Arial, sans-serif;
    display: flex;
    
    align-items: center;     /* 수직 중앙 정렬 */
    min-height: 100vh;       /* 화면 전체 높이를 사용 */
    background-color: #f5f5f5;
    
    display: flex;
    flex-direction: column; /* 세로 방향 정렬 */
    margin-top: 80px;
    justify-content: flex-start; /* 위에서부터 정렬 */
    min-height: 100vh;
    overflow-y: auto; /* 세로 스크롤 허용 */
}

/* 다크 모드 */
[data-theme="dark"] body {
    background-color: #444; /* 다크 모드 배경색 */
    color: #eee; /* 다크 모드 텍스트 색상 */
}

html, body {
    height: 100%;
    margin: 0; /* 기본 여백 제거 */
    padding: 0;
    overflow-x: hidden; /* 가로 스크롤 방지 */
}

.App {
    width: 90%;
    max-width: 900px;
    margin: 20px auto;
}

h1 {
    text-align: center;
    color: #333;
}

.memo-form {
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 20px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
}

.memo-form h2 {
    margin: 0 0 10px;
    color: #333;
}

.memo-form-content {
    display: flex;
    flex-direction: column;
}

.memo-title {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
    background-color: #f8f8f8;
    color: #666;
    box-sizing: border-box;
}

.memo-content {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
    height: 100px;
    resize: vertical;
    box-sizing: border-box;
}

.save-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
    align-self: flex-start;
}

.save-button:hover {
    background-color: #0056b3;
}

.checkmark {
    color: blue; /* 체크표시 색상 */
    margin-left: 5px;
}

.memo-display {
    margin-top: 20px;
    padding: 10px;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
}

/* 기본 라이트 모드 */
:root {
    --bg-color: #ffffff;
    --text-color: #000000;
    --navbar-bg-color: #f8f9fa;
    --navbar-text-color: #343a40;
}

/* 다크 모드 */
[data-theme="dark"] {
    --bg-color: #343a40;
    --text-color: #ffffff;
    --navbar-bg-color: #222831;
    --navbar-text-color: #eeeeee;
}

.navbar {
    background-color: var(--navbar-bg-color);
    color: var(--navbar-text-color);
}

.theme-toggle-button {
    position: absolute; /* Positioned relative to parent */
    top: 10px; 
    right: 10px; 
    background-color: var(--navbar-text-color);
    color: var(--navbar-bg-color);
    border: none;
    margin: 10px;
    padding: 0.5rem 1rem;
    cursor: pointer;
    border-radius: 5px;
}

.theme-toggle-button:hover {
    opacity: 0.8;   
}
