.auth-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 50vh;
    background-color: hsl(0, 0%, 98%);
}

.auth-form {
    background-color: #f9f9f9;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    width: 100%;
    max-width: 400px;
}

.auth-form h2 {
    text-align: center;
    margin-bottom: 1.5rem;
}

.form-group {
    margin-bottom: 1rem;
}

.form-group label {
    display: block;
    margin-bottom: 0.5rem;
    font-size: 0.9rem;
    color: #333;
}

.form-group input {
    width: 96%;
    padding: 0.5rem;
    border-radius: 5px;
    font-size: 1rem;
}

.login_button,
.register_button {
    width: 100%;
    padding: 0.75rem;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    margin-top: 1rem;
}

.login_button:hover,
.register_button:hover {
    background-color: #0056b3;
}

.form-link {
    margin-top: 1rem;
    text-align: center;
    font-size: 0.9rem;
    color: #0066cc;
    cursor: pointer;
}

.form-link:hover {
    text-decoration: underline;
}

/* 다크 모드 스타일 */
[data-theme="dark"] .auth-container {
    background-color: #444; /* 어두운 배경 */
    color: #e0e0e0; /* 텍스트 색상 */
}

[data-theme="dark"] .auth-form {
    background-color: #333; /* 어두운 배경 */
    color: #e0e0e0; /* 텍스트 색상 */
    border: 1px solid #444; /* 어두운 테두리 */
}

[data-theme="dark"] .auth-input {
border: 1px solid #555; /* 어두운 테두리 색상 */
background-color: #222; /* 어두운 입력 배경 */
color: #e0e0e0; /* 텍스트 색상 */
}

[data-theme="dark"] .auth-input:focus {
border-color: #c0c0c0; /* 포커스 시 밝은 테두리 색상 */
}

[data-theme="dark"] .form-group label {
    color: #999; /* 포커스 시 밝은 테두리 색상 */
}