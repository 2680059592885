.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* 다른 요소보다 위에 오도록 */
}

.loading-text {
    font-size: 24px;
    color: #fff;
    font-weight: bold;
}

.dot1, .dot2, .dot3 {
    animation: dots 1.5s infinite steps(1, end);
}

.dot1 {
    animation-delay: 0s;
}

.dot2 {
    animation-delay: 0.3s; /* 점이 0.3초 지연 후 시작 */
}

.dot3 {
    animation-delay: 0.6s; /* 점이 0.6초 지연 후 시작 */
}

@keyframes dots {
    0%, 20% {
        opacity: 0;
    }
    40% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}
