.user-note-section {
    display: flex;
    flex-direction: column;
    /* height: 100%;  */
    width: 100%;
    max-width: 1000px;
    padding: 2vh;
    border: 1px solid #ddd; /* 기본 테두리 색 */
    border-radius: 10px;
    margin-left: auto; /* 왼쪽 여백 자동으로 설정하여 중앙 정렬 */
    margin-right: auto; /* 오른쪽 여백 자동으로 설정하여 중앙 정렬 */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    background-color: rgb(250, 250, 250); 

    /* 텍스트 개행 및 가로 길이 유지 */
    word-wrap: break-word; /* 단어가 길 경우 개행 */
    overflow-wrap: break-word; /* 길이가 긴 텍스트 개행 */
    white-space: normal; /* 공백을 기준으로 자동 줄바꿈 */
    overflow: hidden; /* 넘치는 콘텐츠를 숨김 */
}

/* 다크 모드 */
[data-theme="dark"] .user-note-section {
    background-color: #555; /* 어두운 모드에서의 배경색 */
    border: 1px solid #666; /* 다크 모드 테두리 색 */
    color: #eee; /* 텍스트 색 */
}

.user-note-section h2 {
    font-size: 1.5em;
    margin-bottom: 15px;
}

/* 다크 모드 */
[data-theme="dark"] .note-title {
    background-color: #555; /* 어두운 모드에서의 배경색 */
    border: 1px solid #777; /* 다크 모드 테두리 색 */
    color: #eee; /* 텍스트 색 */
}

/* 내용 입력 필드 */
.note-content {
    flex-grow: 1; /* 부모 컨테이너에서 남은 공간을 차지하도록 설정 */
    height: 558px;
    padding: 10px;
    border: 1px solid #ccc; /* 기본 테두리 색 */
    border-radius: 5px;
    font-size: 1em;
    background-color: rgb(250, 250, 250); 
    overflow: auto; /* 내용이 넘칠 경우 스크롤 추가 */
}

/* 다크 모드 */
[data-theme="dark"] .note-content {
    background-color: #444; /* 어두운 모드에서의 배경색 */
    border: 1px solid #666;
    color: #eee; /* 텍스트 색 */
}

/* 저장 버튼 */
.save-button {
    display: inline-block;
    padding: 10px 15px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
}

/* 다크 모드 */
[data-theme="dark"] .save-button {
    background-color: #0056b3; /* 어두운 모드에서의 버튼 색상 */
}

.save-button:hover {
    background-color: #0056b3; /* 기본 모드에서의 호버 색상 */
}

/* 다크 모드 호버 상태 */
[data-theme="dark"] .save-button:hover {
    background-color: #004494; /* 어두운 모드에서의 호버 색상 */
}

/* Toast 스타일 알림 */
.saved-notification {
    position: fixed;
    bottom: 20px; /* 화면 하단에 위치 */
    right: 20px; /* 화면 오른쪽에 위치 */
    background-color: rgba(0, 0, 0, 0.8); /* 반투명한 검정색 배경 */
    color: white; /* 글씨는 흰색 */
    padding: 10px 20px;
    border-radius: 8px; /* 둥근 모서리 */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* 부드러운 그림자 */
    font-size: 14px; /* 글씨 크기 */
    opacity: 0; /* 처음에는 보이지 않음 */
    transform: translateY(20px); /* 아래에서 올라오는 애니메이션 효과 */
    transition: opacity 0.3s ease, transform 0.3s ease; /* 부드러운 애니메이션 */
}

/* 저장되었을 때 알림이 표시되는 상태 */
.saved-notification.show {
    opacity: 1; /* 완전히 보임 */
    transform: translateY(0); /* 제자리에 */
}

/* Quill toolbar 아이콘 색상 변경 */
[data-theme="dark"] .ql-toolbar .ql-stroke,
.ql-toolbar .ql-fill {
    color: #ccc;
    stroke: #ccc; /* 아이콘 색상 */
    
}

[data-theme="dark"] .ql-toolbar.ql-snow {
    background-color: #444;
    border: 1px solid #777;
}

/* 툴바의 텍스트 색상 변경 */
[data-theme="dark"] .ql-toolbar .ql-picker-label,
.ql-toolbar .ql-picker-item {
    color: #ccc; /* 텍스트 색상 */
}

@media (max-width: 927px) {
    .note-content {
        height: 650px;
    }
}


/* 책갈피 */
.note-tabs {
    display: flex;
    align-items: end;
    gap: 8px;
    padding: 0 10px;
    overflow-x: auto;
    white-space: nowrap;
}

.note-tab {
    position: relative;
    padding: 10px 15px;
    background-color: rgb(230, 230, 230);
    border-radius: 8px 8px 0 0;
    font-size: 14px;
    cursor: pointer;
    transition: background 0.3s ease, color 0.3s ease;
}

[data-theme="dark"] .note-tab {
    background-color: #999;
    color: #EEEEEE;
}

.note-tab:hover {
    background-color: rgb(200, 200, 200);
    color: #333;
}


[data-theme="dark"] .note-tab:hover {
    background-color: #999;
    color: #333;
}

.note-tab.active {
    background-color: #339AF0;
    color: white;
    font-weight: bold;
}

.note-tab.active:hover {
    background-color: #339AF0;
}

.title-input-container {
    display: flex;
    align-items: center;
    gap: 8px;
    position: relative;
    width: auto;
    max-width: 100%;
}

.note-tabs input {
    padding: 5px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
    min-width: 30px;
    max-width: 70px;
    flex-grow: 1;
}

.delete-note-btn {
    background-color: #ececec; 
    color: rgb(255, 95, 95); 
    border: none; 
    border-radius: 5px; 
    padding: 5px 10px; 
    cursor: pointer; 
    font-size: 14px; 
    transition: background-color 0.3s ease; /* Transition for background color */
}

.delete-note-btn:hover {
background-color: #ffb8b8; /* Background change on hover */
}

.add-note-btn {
    padding: 5px 10px;
    font-size: 18px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    transition: background 0.3s ease;
}

.add-note-btn:hover {
    background-color: #0073ef;
}
