.file-upload-container {
    display: flex;
    align-items: center; /* 세로 중앙 정렬 */
    gap: 10px; /* 버튼과 이미지 간의 간격 */
    margin: 20px 0;
  }
  
  .file-upload-input {
    display: none; /* 숨김 처리 */
  }
  
  .file-upload-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    margin-top: 80px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .file-upload-button:hover {
    background-color: #0056b3;
  }
  