.memo-app-container {
    font-family: Arial, sans-serif;
    max-width: 90vw; /* 너무 크면 조절되도록 */
    padding: 1vh;
    margin: 20px;
    margin-top: 80px;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    background-color: #fff; /* 기본 배경색 */
    display: flex;
    gap: 30px; /* memo-section과 user-note-section 간의 간격을 30px로 설정 */
    flex-wrap: nowrap; /* 자식 요소가 줄 바꿈 없이 가로로 나열되도록 설정 */
    transition: margin-top 0.1s ease-in-out;
}

/* 다크 모드 */
[data-theme="dark"] .memo-app-container {
    background-color: #444; /* 어두운 배경색 */
    border: 1px solid #444; /* 다크 모드 테두리 색 */
    color: #eee; /* 어두운 모드에서의 텍스트 색 */
}

.memo-section {
    max-width: 750px; /* 최대 가로 길이 설정 */
    flex-grow: 1;
    flex: 0 0 48%; /* flex-grow와 flex-shrink을 모두 0으로 설정하여 고정 너비 유지 */
    padding: 2vh;
    margin-left: auto; /* 왼쪽 여백 자동으로 설정하여 중앙 정렬 */
    margin-right: auto; /* 오른쪽 여백 자동으로 설정하여 중앙 정렬 */
    border: 1px solid #ddd; 
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    background-color: rgb(250, 250, 250); 
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    box-sizing: border-box;
    display: flex;
    justify-content: space-around;
    flex-direction: column; 
    align-items: center;
}

/* 다크 모드 */
[data-theme="dark"] .memo-section {
    background-color: #555; 
    border: 1px solid #777; 
}

/* 화면이 가로로 넓을 때 가로로 배치 */
@media (min-width: 1140px) {
    .memo-app-container {
        flex-direction: row;
        justify-content: space-between;
        min-height: 80vh;
        max-height: 85vh;
    }
    .memo-section, .user-note-section {
        flex: 1 1 48%; /* 각 섹션이 48% 너비를 가지도록 설정 */
        min-width: 500px;
        max-width: 35vw;
    }
}

/* 화면이 가로로 좁을 때 세로 배치 및 중앙 정렬 */
@media (max-width: 1140px) {
    .memo-app-container {
        flex-direction: column; /* 세로 배치 */
        align-items: center; /* 자식 요소를 중앙 정렬 */
        gap: 20px; /* 섹션 간의 간격 */
    }

    .memo-section, .user-note-section {
        flex: 0 0 auto; /* 유동적인 크기 설정 */
        width: 100%; /* 좌우 여백을 제외한 전체 크기 */
        max-width: 600px; /* 최대 너비 설정 */
        margin: 0 auto; /* 수평 중앙 정렬 */
    }
}

/* 화면이 가로로 좁을 때 세로 배치 */
@media (max-width: 786px) {
    .memo-app-container {
        flex-direction: column;
        align-items: center;
        /* padding: 10px 0; */
        gap: 20px;
        height: 100vh;
    }

    .memo-section, .user-note-section {
        flex: 0 0 auto; /* 고정 크기 */
        width: 100%; /* 부모 컨테이너의 너비를 동일하게 사용 */
        max-width: 600px; /* 최대 가로 길이 제한 */
        margin: 0 auto; /* 좌우 중앙 정렬 */
        box-sizing: border-box; /* 패딩 및 테두리를 포함한 크기 계산 */
    }
}

/* 화면이 가로로 더 좁은 화면에서도 동일한 너비 유지 */
@media (max-width: 480px) {
    .memo-app-container {
        gap: 15px; /* 섹션 간 간격 줄이기 */
    }

    .memo-section, .user-note-section {
        padding: 10px; /* 내부 여백 추가 */
        width: calc(100% - 20px); /* 부모 컨테이너의 크기를 기준으로 여백 계산 */
    }
}
